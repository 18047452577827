import { useState, useEffect, useReducer } from "react";
import { db } from "../firebase/config";
import { collection, addDoc,Timestamp } from "firebase/firestore";

const initialState={
    loading:null,
    error:null
}

const insertReducer = (state, action) => {
    switch(action.type){
        case "LOADING":
            return {loading:true, erro:null}
        case "INSERTED_DOC":
        return {loading:false , erro:null}
        case "ERROR":
            return {loading: false , erro: action.payload}
        default:
            return state
    }
}

export const  useInsertDocument = (docCollection) =>{
    const [response, dispatch] = useReducer(insertReducer, initialState)

    const [cancelled , setCancelled] = useState(false)

    const checkCancelBeforeDispatch = (action) =>{
        if(!cancelled){
           dispatch(action)
        }
    }

    const insertDocument = async (document) => {

        
        checkCancelBeforeDispatch({
            type:"LOADING",
         })
        try{
           const newDocument = {...document, createdAt:Timestamp.now()};

           const insertedDocument = await addDoc(
            collection(db, docCollection),
            newDocument
           );

           checkCancelBeforeDispatch({
              type:"INSERTED_DOC",
              payload: insertedDocument
           })
        }catch(erro){
            checkCancelBeforeDispatch({
                type:"ERROR",
                payload: erro.message,
        })
    }

    }
    useEffect(()=>{
        return () => setCancelled(true)
    },[])
    return{insertDocument, response}
}
