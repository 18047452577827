
function Footer () {
    return(
        <div className="flex flex-col items-center">
            <div className=""></div>
        <footer className="w-full h-[300px] flex text-center justify-center items-end mb-[20px]">
           <h2 className="  flex justify-center">©2024 todos direitos reservados a  Israel Advogados.</h2>
        </footer>
        </div>
    )
}
export default Footer